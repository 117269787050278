import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-meal-ingredients"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "dropdown-food-group-title" }
const _hoisted_6 = { class: "dropdown-food" }
const _hoisted_7 = { class: "dropdown-food-macros" }
const _hoisted_8 = {
  key: 0,
  class: "item-macro"
}
const _hoisted_9 = {
  key: 1,
  class: "item-macro"
}
const _hoisted_10 = {
  key: 2,
  class: "item-macro"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "total-row" }
const _hoisted_15 = ["colspan"]
const _hoisted_16 = { class: "total-calories-cell" }
const _hoisted_17 = { class: "total-bites-cell" }
const _hoisted_18 = { class: "total-bites-cell" }
const _hoisted_19 = { class: "total-bites-cell" }
const _hoisted_20 = {
  key: 0,
  class: "error-bites"
}

import { ref, onMounted, computed } from 'vue';
import { MenuMealDetailDto, Food, MenuMealDto, MacroType, getMacroIcon } from '@/models/NutritionalPlan';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useI18n } from 'vue-i18n';
import { Intolerances } from '@/models/PatientInfo';


export default /*@__PURE__*/_defineComponent({
  __name: 'RationsTable',
  props: {
  editMode: {
    type: Boolean,
    default: false,
  },
  foods: {
    type: Array as () => MenuMealDetailDto[],
    required: true,
  },
  mealMenu: {
    type: Object as () => MenuMealDto,
    required: true,
  },
  foodIntolerances: {
    type: Array as () => Intolerances[],
    required: true,
  },
},
  emits: [
  'on-new-food',
  'on-delete-food',
  'on-quantiy-change',
  'on-food-change',
  'on-create-alternative',
],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emits = __emit;
const props = __props;
const foodsOptions = ref<Food[]>([]);

const foodsOptionsDropdown = computed(() => {
  const mapFood = (food: Food) => ({
    label: `${t('nutrition.menu.foods.' + food.id)}`,
    value: food.id,
    proteinBites: food.proteinBites,
    carbohydratesBites: food.carbohydratesBites,
    fatBites: food.fatBites,
  });

  const macroTypes = [MacroType.Protein, MacroType.Carbs, MacroType.Fat];

  const dropdownOptions = macroTypes.map((macroType) => ({
    type: macroType,
    label: t(`nutrition.${macroType.toUpperCase()}`),
    items: foodsOptions.value
      .filter((food) => food.macroType === macroType)
      .filter((food) => !hasIntolerances(food))
      .map(mapFood),
  }));

  return dropdownOptions;
});

const hasIntolerances = (food: Food): boolean => {
  return (
    props.foodIntolerances !== undefined &&
    props.foodIntolerances !== null &&
    props.foodIntolerances.filter(
      (intolerance) =>
        food.foodIntolerances !== undefined &&
        food.foodIntolerances !== null &&
        food.foodIntolerances.indexOf(intolerance) !== -1,
    ).length !== 0
  );
};

const rationsOptionsDropdown = computed(() => {
  const array = [];
  for (let i = 0.5; i <= 10; i += 0.5) {
    array.push({ label: i % 1 === 0 ? i.toString() : i.toFixed(1), value: i });
  }
  return array;
});

onMounted(async () => {
  await fetchFoods();
});

const fetchFoods = async () => {
  const result = await nutritionalPlanService.findAllFoods();

  if (!(result instanceof ServiceError)) {
    foodsOptions.value = result;
    foodsOptions.value = foodsOptions.value.sort((a, b) => {
      const aLabel = t('nutrition.menu.foods.' + a.id);
      const bLabel = t('nutrition.menu.foods.' + b.id);
      return aLabel.localeCompare(bLabel);
    });
  }
};

const totalBitesProteins = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.proteinBites) * row.bites || 0;
  }, 0);
});

const totalBitesFats = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.fatBites) * row.bites || 0;
  }, 0);
});

const totalBitesCarbohydrates = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.carbohydratesBites) * row.bites || 0;
  }, 0);
});

const totalFoodCalories = (menuMealDetail: MenuMealDetailDto) => {
  const { food, bites } = menuMealDetail;
  return (food.carbohydratesBites * 4 + food.proteinBites * 4 + food.fatBites * 9) * 10 * bites;
};

const totalFoodsCalories = computed(() => {
  let total = 0;
  props.foods.forEach((food) => {
    const { carbohydratesBites, proteinBites, fatBites } = food.food;
    total += (carbohydratesBites * 4 + proteinBites * 4 + fatBites * 9) * 10 * food.bites;
  });
  return total;
});

const errorInTotalBites = computed(() => {
  return (
    totalBitesProteins.value !== props.mealMenu.proteinBites ||
    totalBitesFats.value !== props.mealMenu.fatBites ||
    totalBitesCarbohydrates.value !== props.mealMenu.carbohydratesBites
  );
});

const onQuantityChange = (newValue: any, index: number) => {
  emits('on-quantiy-change', newValue, index);
};

const onDeleteFood = (indexFood: number) => {
  emits('on-delete-food', indexFood);
};

const onNewFoodClick = () => {
  emits('on-new-food');
};

const onFoodChange = (newFood: any, index: number) => {
  const food = foodsOptions.value.find((food) => food.id === newFood.value);
  if (food) {
    emits('on-food-change', food, index);
  }
};

const onCreateAlternative = () => {
  emits('on-create-alternative', props.mealMenu.id);
};

return (_ctx: any,_cache: any) => {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (__props.foods)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", {
          "aria-label": _ctx.$t('nutrition.menu.ration')
        }, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (__props.editMode)
                ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString(_ctx.$t('nutrition.menu.ration')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.food')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.quantity')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.proteins')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.fats')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.carbs')), 1),
              _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.foods, (food, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (__props.editMode)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                      _createVNode(_component_Dropdown, {
                        id: food.bites,
                        modelValue: food.bites,
                        "onUpdate:modelValue": ($event: any) => ((food.bites) = $event),
                        options: rationsOptionsDropdown.value,
                        "option-label": "label",
                        "option-value": "value",
                        class: _normalizeClass({
                'no-editable': !__props.editMode,
              }),
                        onChange: ($event: any) => (onQuantityChange($event, index))
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "options", "class", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("td", null, [
                  (__props.editMode)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        key: 0,
                        id: food.foodId,
                        modelValue: food.foodId,
                        "onUpdate:modelValue": ($event: any) => ((food.foodId) = $event),
                        filter: "",
                        options: foodsOptionsDropdown.value,
                        optionGroupLabel: "label",
                        optionGroupChildren: "items",
                        optionLabel: "label",
                        optionValue: "value",
                        class: _normalizeClass({
                  'no-editable': !__props.editMode,
                }),
                        panelProps: { style: { width: '450px' } },
                        onChange: ($event: any) => (onFoodChange($event, index))
                      }, {
                        optiongroup: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("i", {
                              class: _normalizeClass(_unref(getMacroIcon)(slotProps.option.type)),
                              "aria-hidden": "true"
                            }, null, 2),
                            _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                          ])
                        ]),
                        option: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1),
                            _createElementVNode("div", _hoisted_7, [
                              (slotProps.option.proteinBites)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _cache[2] || (_cache[2] = _createElementVNode("i", {
                                      class: "icon-proteins icon-macro",
                                      "aria-hidden": "true"
                                    }, null, -1)),
                                    _createElementVNode("span", null, _toDisplayString(slotProps.option.proteinBites), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (slotProps.option.carbohydratesBites)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _cache[3] || (_cache[3] = _createElementVNode("i", {
                                      class: "icon-hc icon-macro",
                                      "aria-hidden": "true"
                                    }, null, -1)),
                                    _createElementVNode("span", null, _toDisplayString(slotProps.option.carbohydratesBites), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (slotProps.option.fatBites)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _cache[4] || (_cache[4] = _createElementVNode("i", {
                                      class: "icon-fats icon-macro",
                                      "aria-hidden": "true"
                                    }, null, -1)),
                                    _createElementVNode("span", null, _toDisplayString(slotProps.option.fatBites), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["id", "modelValue", "onUpdate:modelValue", "options", "class", "onChange"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(`${_unref(t)('nutrition.menu.foods.' + food.food.id)}`), 1)
                      ], 64))
                ]),
                _createElementVNode("td", null, [
                  _createTextVNode(_toDisplayString(food.food.quantity * food.bites) + " ", 1),
                  (food.food.units)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t(`nutrition.menu.units.${food.food.units}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", null, _toDisplayString(totalFoodCalories(food)), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.proteinBites * food.bites), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.fatBites * food.bites), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.carbohydratesBites * food.bites), 1),
                _createElementVNode("td", null, [
                  (__props.editMode)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "p-button-only-icon",
                        onClick: ($event: any) => (onDeleteFood(index))
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("i", { class: "icon-trash_full" }, null, -1)
                      ]), 8, _hoisted_12))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128)),
            (__props.editMode)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                  _cache[7] || (_cache[7] = _createElementVNode("td", null, null, -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("td", null, null, -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("td", null, null, -1)),
                  _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1)),
                  _cache[13] || (_cache[13] = _createElementVNode("td", null, null, -1)),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: "p-button-only-icon",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (onNewFoodClick()))
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "icon-add-square" }, null, -1)
                    ]))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("tfoot", null, [
            _createElementVNode("tr", _hoisted_14, [
              _createElementVNode("td", {
                class: "total-cell",
                colspan: __props.editMode ? '2' : '1'
              }, _toDisplayString(_ctx.$t('nutrition.menu.total-bites')), 9, _hoisted_15),
              _cache[14] || (_cache[14] = _createElementVNode("td", null, null, -1)),
              _createElementVNode("td", _hoisted_16, _toDisplayString(totalFoodsCalories.value), 1),
              _createElementVNode("td", _hoisted_17, _toDisplayString(totalBitesProteins.value), 1),
              _createElementVNode("td", _hoisted_18, _toDisplayString(totalBitesFats.value), 1),
              _createElementVNode("td", _hoisted_19, _toDisplayString(totalBitesCarbohydrates.value), 1),
              _cache[15] || (_cache[15] = _createElementVNode("td", null, null, -1))
            ])
          ])
        ], 8, _hoisted_2),
        (errorInTotalBites.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _cache[16] || (_cache[16] = _createElementVNode("i", { class: "icon-error_outline" }, null, -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.error-total-bites')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-medium",
          label: _ctx.$t('nutrition.menu.button-create-alternative'),
          onClick: onCreateAlternative
        }, null, 8, ["label"])
      ]))
    : _createCommentVNode("", true)
}
}

})